import React, { useEffect, useRef, useState } from 'react'

import {
  ArchiveBoxIcon,
  Cog8ToothIcon,
  DocumentDuplicateIcon,
  FolderOpenIcon,
  LockClosedIcon,
  Squares2X2Icon,
  TrashIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { MenuList } from '@mui/material'
import Drawer from '@mui/material/Drawer'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import { ACLPrincipalType, TableName } from 'types/graphql'
import { useBoolean } from 'usehooks-ts'

import Button from 'src/components/Library/Button/Button'
import {
  defaultConfig,
  SettingsViews,
  viewTitles,
} from 'src/components/Library/RecordSettingsModal/config'
import GeneralSettings from 'src/components/Library/RecordSettingsModal/Views/GeneralSettings'
import TemplateSettings from 'src/components/Library/RecordSettingsModal/Views/TemplateSettings'
import Modal from 'src/components/Modal/Modal'
import useACL from 'src/lib/hooks/ACL/useACL'
import { useConfirm } from 'src/lib/hooks/Confirmation/index'
import { UseBooleanReturnType } from 'src/lib/hooks/UseBoolean'
import { getPlaceholderText } from 'src/Util'

import AccessSettings, { AccessMethods } from './Views/AccessSettings'

interface MoveList {
  label: string
  value: number
}

export interface RecordCustomField {
  name: string
  value: Date | string | number
  type: 'date' | 'text' | 'number'
}

export interface Record {
  id: number
  type: TableName
  name: string
  description?: string
  status?: string
  parentId?: number
  isTemplate?: boolean
  isGlobal?: boolean
  moveList?: MoveList[]
  customFields?: RecordCustomField[]
}
export interface RecordSettingsModalProps {
  modalVisible: UseBooleanReturnType
  deleteConfirmOverride?: any
  record: Record | null
  onSave?: (record: Record) => void
  onDelete?: (record: Record) => void | null
  principalTypes?: ACLPrincipalType[]
  config?: SettingsViews[]
  ACL?: ReturnType<typeof useACL>
}

const RecordSettingsModal: React.FC<RecordSettingsModalProps> = ({
  modalVisible,
  deleteConfirmOverride,
  record,
  principalTypes,
  config = defaultConfig,
  onSave,
  onDelete,
}) => {
  const [activeView, setActiveView] = useState<SettingsViews>(config[0])
  const [newRecord, setNewRecord] = useState<Record | null>(record)
  const [status, setStatus] = useState<string>(record?.status)
  const isTemplate = useBoolean(record?.isTemplate)
  const loading = useBoolean(false)
  const viewIcons = {
    [SettingsViews.GENERAL]: { src: Cog8ToothIcon },
    [SettingsViews.ACCESS]: { src: UserGroupIcon },
    [SettingsViews.CLONING]: { src: DocumentDuplicateIcon },
    [SettingsViews.MOVE]: { src: FolderOpenIcon },
    [SettingsViews.TEMPLATE]: { src: Squares2X2Icon },
    [SettingsViews.PRIVATE]: { src: LockClosedIcon },
    [SettingsViews.ARCHIVE]: { src: ArchiveBoxIcon },
  }
  const placeholderText = getPlaceholderText(record?.type)
  const aclRef = useRef<AccessMethods>()
  const confirm = useConfirm()
  useEffect(() => {
    setNewRecord({
      ...newRecord,
      isTemplate: isTemplate?.value,
    })
  }, [isTemplate?.value])

  // ToDo: Add keyboard shortcut for save
  // useEffect(() => {
  //   function handleEscapeKey(event: KeyboardEvent) {
  //     if (event.code === 'Enter') {
  //       handleSave()
  //     }
  //   }
  //   document.addEventListener('keydown', handleEscapeKey)
  //   return () => document.removeEventListener('keydown', handleEscapeKey)
  // }, [])

  const handleCustomFieldChange = (
    index: number,
    value: string | number | Date,
  ) => {
    const newCustomFields = newRecord?.customFields
    newCustomFields[index].value = value
    setNewRecord({ ...newRecord, customFields: newCustomFields })
  }

  const handleSave = async () => {
    loading.setTrue()
    onSave?.(newRecord)
    if (config.includes(SettingsViews.ACCESS)) {
      await aclRef.current?.saveACL()
    }
    loading.setFalse()
    modalVisible.setFalse()
  }
  const getButton = (view: SettingsViews) => {
    const Icon = viewIcons[view]?.src
    return (
      <Button
        variant="text"
        onClick={() => {
          setActiveView(view)
        }}
        startIcon={<Icon className="h-5 w-5" />}
        className={`justify-start text-sm font-medium leading-5 text-gray-500 hover:bg-transparent hover:text-indigo-600 ${
          activeView === view && 'text-indigo-600'
        }`}
        buttonDataTestId={`rsm-${viewTitles[view]}-button`}
      >
        {viewTitles[view]}
      </Button>
    )
  }

  const isDeleteEnabled = typeof onDelete === 'function'

  return (
    <Modal
      onClose={modalVisible.setFalse}
      open={modalVisible.value}
      title="Edit Resource"
      disablePortal={false}
      dialogClassName={'!p-0 max-w-[720px]'}
    >
      <div className="flex h-full flex-row">
        <Drawer
          sx={{
            width: '240px',
            '& .MuiDrawer-paper': {
              position: 'relative',
              width: '240px',
              boxSizing: 'border-box',
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <div className="px-2">
            {record?.status && (
              <div className="items-center rounded-md bg-gray-100 p-1 transition-all duration-300 ease-in-out">
                <Button
                  className={`w-1/2 text-gray-600 ${
                    status !== 'PUBLISHED'
                      ? 'bg-white shadow hover:bg-white'
                      : 'hover:bg-transparent'
                  }`}
                  fullWidth={false}
                  value={'Draft'}
                  size={'small'}
                  variant={'text'}
                  onClick={() => {
                    setStatus('DRAFT')
                    setNewRecord({ ...newRecord, status: 'DRAFT' })
                  }}
                >
                  Draft
                </Button>
                <Button
                  className={`w-1/2 text-gray-600 transition-all duration-300 ease-in-out ${
                    status === 'PUBLISHED'
                      ? 'bg-white text-green-500 shadow hover:bg-white'
                      : 'hover:bg-transparent'
                  }`}
                  buttonDataTestId={'rsm-publish-button'}
                  fullWidth={false}
                  value={'PUBLISHED'}
                  size={'small'}
                  variant={'text'}
                  onClick={() => {
                    setStatus('PUBLISHED')
                    setNewRecord({ ...newRecord, status: 'PUBLISHED' })
                  }}
                  endIcon={
                    <CheckCircleIcon
                      className={`${
                        status !== 'PUBLISHED' ? 'h-0 w-0' : 'h-6 w-6'
                      } text-green-500 transition-all duration-300 ease-in-out`}
                      data-testid={`rsm-published-icon-${status}`}
                    />
                  }
                >
                  Published
                </Button>
              </div>
            )}

            <h1 className="text-xs font-medium uppercase leading-7 text-gray-400">
              Settings
            </h1>
            <MenuList>
              {config.map((view) => (
                <MenuItem
                  key={view}
                  className={`rounded-md ${
                    view === activeView && 'bg-gray-200 text-indigo-600'
                  } p-0`}
                >
                  {getButton(view)}
                </MenuItem>
              ))}
              <MenuItem className={'rounded-md p-0'}>
                <Button
                  variant="text"
                  onClick={() => {
                    if (!isDeleteEnabled) return
                    if (deleteConfirmOverride) {
                      deleteConfirmOverride({
                        afterwards: async () => {
                          onDelete?.(newRecord)
                          if (config.includes(SettingsViews.ACCESS)) {
                            await aclRef.current?.deleteACL()
                          }
                          modalVisible.setFalse()
                        },
                      })
                    } else {
                      confirm({
                        title: 'Are you sure?',
                        description: `This action cannot be undone, and the ${placeholderText} data will be lost.`,
                        confirmationText: 'Delete',
                      }).then(async (isConfirmed) => {
                        if (!isConfirmed) return
                        onDelete?.(newRecord)
                        if (config.includes(SettingsViews.ACCESS)) {
                          await aclRef.current?.deleteACL()
                        }
                        modalVisible.setFalse()
                      })
                    }
                  }}
                  startIcon={<TrashIcon className="h-5 w-5" />}
                  className={`justify-start text-sm font-medium leading-5 ${
                    isDeleteEnabled ? 'text-red-500' : 'text-grey-500'
                  } hover:bg-transparent`}
                  disabled={!isDeleteEnabled}
                >
                  Delete
                </Button>
              </MenuItem>
            </MenuList>
          </div>
        </Drawer>
        <div className="flex h-full w-full flex-col p-6">
          {
            {
              [SettingsViews.GENERAL]: (
                <GeneralSettings
                  recordTitle={newRecord?.name}
                  handleChange={(e) => {
                    setNewRecord({ ...newRecord, name: e.target.value })
                  }}
                  customFields={
                    newRecord?.customFields ? newRecord.customFields : []
                  }
                  handleCustomFieldChange={handleCustomFieldChange}
                />
              ),
              [SettingsViews.ACCESS]: (
                <AccessSettings
                  ref={aclRef}
                  principalTypes={principalTypes}
                  record={newRecord}
                  onSetNewRecord={setNewRecord}
                />
              ),
              [SettingsViews.CLONING]: <>TODO</>,
              [SettingsViews.MOVE]: <>TODO</>,
              [SettingsViews.TEMPLATE]: (
                <TemplateSettings
                  isTemplate={isTemplate}
                  placeholderText={placeholderText}
                />
              ),
              [SettingsViews.PRIVATE]: <>TODO</>,
              [SettingsViews.ARCHIVE]: <>TODO</>,
            }[activeView]
          }
        </div>
      </div>
      <div className="flex w-full flex-row justify-end border border-t-gray-300 bg-gray-100 p-2">
        <Stack direction={'row'} spacing={2}>
          <Button
            disabled={loading.value}
            variant={'text'}
            onClick={modalVisible.setFalse}
          >
            Cancel
          </Button>
          <Button
            buttonDataTestId="rsm-save"
            disabled={loading.value}
            onClick={handleSave}
          >
            Save
          </Button>
        </Stack>
      </div>
    </Modal>
  )
}

export default RecordSettingsModal
